:root {
  --font-body: Montserrat,Arial,Helvetica,sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.1;
}

p, .text-contain div, .text-contain p, h6 {
  color: var(--black);
  text-align: justify;
  /* font-size: 1rem; */
  font-size: 1rem;
}
.overlay p, .overlay .text-contain div, .overlay .text-contain p, .overlay h6 {
  text-align: center;
  color: var(--white);
}
p span, .text-contain div span, .text-contain p span, h6 span {
  color: var(--primary);
  text-transform: uppercase;
  font-weight: bold;
}
#contact p, #contact .text-contain div, #contact .text-contain p, #contact h6 {
  font-size: 0.7rem;
  margin: 0 0 0.5rem;
}

h1 {
  font-weight: normal;
  font-size: 1.3rem;
  text-transform: uppercase;
  margin: 1.3rem 0 1.3rem;
}

h2 {
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0 0 1rem;
  color: var(--grey);
  font-style: italic;
}
@media only screen and (max-width: 1349px) {
  h2 {
    margin: 0.8rem 0 1rem;
  }
  h2 p, h2 .text-contain div, h2 .text-contain p, h2 h6 {
    font-size: 1.2rem;
  }
}
.bottom-option h2 {
  font-weight: normal;
  margin: 0.5rem auto;
  color: var(--white);
}
@media only screen and (max-width: 768px) {
  .bottom-option h2 {
    padding: 20px 0 10px;
    font-size: 1.2rem;
  }
}

h3 {
  font-weight: bold;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin: 1.5rem 0 0;
  color: var(--primary);
  text-align: right;
  font-style: oblique;
}
.overlay h3 {
  text-align: center;
  color: var(--white);
}
@media only screen and (max-width: 1349px) {
  h3 {
    margin: 20px 0 0;
    line-height: 1.2rem;
  }
}

h4 {
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 2rem 0;
  color: var(--grey);
  font-style: italic;
}
@media only screen and (max-width: 1349px) {
  h4 {
    margin: 2rem 0;
  }
}

#containsTwo h4 {
  margin: 0 0 2rem;
}

h5 {
  margin: 0 0 2rem;
}

h6 {
  margin: 0 0 2rem;
  font-weight: normal;
}

ul {
  margin: 0 0 2rem;
}

#contact a {
  color: var(--primary);
  font-weight: bold;
}

#contact span {
  display: block;
  margin: 0 0 0.5rem;
}