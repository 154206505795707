.container {
  padding: 0 1.5rem;
  margin: 0 auto;
}
@media (min-width: 90em) {
  .container {
    padding: 0;
    max-width: 90rem;
    transform: translate(0, 0);
  }
}

.centerimage {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
}

.centerimage img {
  width: 100%;
  height: auto;
  position: absolute !important;
  top: 50%;
  transform: translate(0, -50%) !important;
  transition: transform 0.2s linear;
  z-index: 0;
}
@media only screen and (max-width: 768px) {
  .centerimage img {
    width: auto;
    height: 100%;
  }
}
@media only screen and (max-width: 768px) {
  #revolution-slider-mid .centerimage img {
    width: 100%;
    height: auto;
  }
}
@media only screen and (max-width: 768px) {
  .right .centerimage img {
    width: 100%;
    height: auto;
    position: relative !important;
    transform: none !important;
  }
}

.right {
  width: 100%;
}