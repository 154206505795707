:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --primary: #4993c8;
  --primary-dark: #000;
  --primary-light: #000;
  --base: #000;
  --base-dark: #000;
  --base-light: #000;
  --secondary: #3d8cc5;
  --secondary-dark: #000;
  --secondary-light: #000;
  --accent: #000;
  --accent-dark: #000;
  --accent-light: #000;
  --text-dark: #000;
  --text-light: #fff;
  --grey: #838287;
  --grey-light: #CCC;
  --gradient1: linear-gradient(90deg, var(--primary), var(--secondary));
  --gradient2: linear-gradient(90deg, var(--primary), var(--base));
}

body {
  background-color: white;
  color: var(--text-dark);
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none;
}

html {
  font-size: 62.5%;
  /* 1REM = 10px */
}
@media only screen and (min-width: 768px) {
  html {
    font-size: 72.5%;
    /* 1REM = 10px */
  }
}
@media only screen and (min-width: 1024px) {
  html {
    font-size: 82.5%;
    /* 1REM = 10px */
  }
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}